.services {
  padding: 40px 0;
}

.serviceText {
  text-align: center;

}

.services h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  text-align: center;
}

.services p {
  font-size: 1.1em;
  line-height: 1.5;
  margin-bottom: 30px;
  width: 1200px;
  margin: 0 auto;
  text-align: center;
  word-wrap: break-word;
}

.services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.service-card {
  display: flex;
  flex-direction: column;
  width: clamp(250px, 300px, 300px);
  margin: 0 10px 20px;
  padding: 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.service-card:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.92);
}

.service-card a {
  display: block;
  overflow: hidden;
}

.service-card img {
  width: 100%;
  height: 200px;
  /* Adjust image height as needed */
  object-fit: cover;
  /* Crop image to fit container */
}

.service-content {
  padding: 10px 20px;
  background-color: #f5f5f5;
}

.service-content h3 {
  font-size: 1.3em;
  margin-bottom: 0px;
  font-weight: 600;
  font-family: var(--font1);
}



@media (max-width: 768px) {
  .services-container {
    flex-direction: column;
  }

  .service-card {
    width: 80%;
    margin-inline: auto;
  }
}

.newCard {
  background-size: cover;
  background-position: bottom;
  border-radius: 10px;
}

.newCard .card-container {
  width: 250px;
  height: 300px;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: .3s ease-in-out;

}

.newCard .card {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: inherit;

}

.newCard .card .front-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  padding-inline-start: 20px;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1)
}

.newCard .card .front-content p {
  font-size: 32px;
  font-weight: 700;
  opacity: 1;
  background: linear-gradient(var(--accent1), var(--primary));
  background: var(--accent1);
  /* background: silver; */
  /* text-shadow: -1px -1px 1px var(--accent1); */
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1)
}

.newCard .card .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  background: linear-gradient(var(--accent1), var(--primary));
  color: #e8e8e8;
  padding: 20px;
  line-height: 1.5;
  border-radius: 5px;
  transform: translateX(-96%);
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}




.card-container:hover {
  cursor: pointer;
  transition: all 1.6s cubic-bezier(0.23, 1, 0.320, 1);
  background-color: rgba(0, 0, 0, 0.5) !important;
}


.card:hover .front-content p {
  /* opacity: 0; */
  background: white;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}