
.quote {
  margin: 40px  0;
    padding: 40px 0;
    background: linear-gradient( var(--accent1), var(--primary));
    color:white;
  }
  
  .quote h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .quote p {
    font-size: 1em;
    margin-bottom: 40px;
    text-align: center;
  }

  .quote button {
    background-color: #CECFD0;
    color: #89023E;
    padding: 10px 20px;
    width: clamp(100px, 50%, 200px);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-inline: auto;
    margin-top: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    transition: .2s ease-in-out;
    font-size: 23px;
  }