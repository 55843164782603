.about-us {
    /* padding: 40px 0; */
    padding-block: 80px 0px;
    text-align: center;
  }
  
  .about-us h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .about-us p {
    font-size: 1.1em;
    line-height: 1.5;
    margin-bottom: 30px;
    width: 1200px; 
    margin: 0 auto; 
    text-align: center; 
    word-wrap: break-word;
  }
  
  .about-us img {
    /* width: 50%; */
    margin: 0 auto;
    border-radius: 5px;
  }
  
  @media (max-width: 768px) {
    .about-us img {
      /* width: 100%; */
    }
  }