@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&family=Dancing+Script:wght@400;600&display=swap");

:root {
  --primary: #89023e;
  --secondary: #082051;
  --accent1: #89023e;
  --font1: "Poppins", sans-serif;
  --font2: "Dancing Script", cursive;
}

.heading-style {
    position: relative; 
    display: inline-block;
    padding-bottom: 5px; 
    text-align: center;
}

.heading-style::after {
    content: '';
    display: block;
    width: 75%; 
    height: 3px; 
    background: var(--primary); 
    position: absolute;
    left: 0;
    bottom: 0;
}