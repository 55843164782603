.navbar-brand img {
  width: 100px;
  margin: 25px 0 0 25px;
}

.navbar-brand h2 {
  color: #fff;
  font-weight: bold;
  font-size: 28px;
  position: absolute;
  top: 30px;
  left: 140px;
}

.navbar-brand{
  display: flex;
  justify-content: space-between;
}

.navbar {
  background-color: var(--secondary) !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.navbar-collapse {
  display: flex;
  justify-content: flex-end !important;
}

.navbar-collapse button {
  padding: 5px 20px;
  background-color: var(--primary);
  color: white;
  margin-inline-start: 20px;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  transition: .2s ease-in-out;
  font-size: 20px;
}

.nav-link {
  width: fit-content !important;
  color: #333;
  font-weight: 500;

}

.nav-item {
  font-size: clamp(18px, 2.5vw, 18px) !important;
}

.contactNumber {
  color: white;
  font-weight: 600 !important;
  font-size: 22px !important;
}

.secondLink {
  display: none !important;
}

@media only screen and (max-width: 990px) {
  .secondLink {
    display: block !important;
  }

  .navbar-collapse {
    display: flex;
    justify-content: flex-start !important;
  }
}

.navbar-nav{
  margin: auto 0px;
  padding-right: 20px;
}