.hero {
  background-image: url("../../../img5.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: start;
  height: 75vh;
}

.header-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 20px;
  font-family: var(--font1);
}

.text-content {
  flex: 1.5;
  text-align: left;
}

.image-content {
  flex: 1;
  text-align: right;
}

.hero img {
  width: 100%;
  height: auto;
}

.hero h1 {
  font-size: 3.5em; /* Adjusted font size */
  margin-bottom: 20px;
  font-weight: 700;
}

.hero p {
  font-size: 1.3em;
  line-height: 1.5;
  margin-bottom: 40px;
}

.hero button {
  background-color: #89023E;
  color: #fff;
  padding: 15px 20px;
  width: clamp(200px, 50%, 300px);
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: .2s ease-in-out;
  font-size: 23px;
}

.hero button:hover {
  background-color: var(--primary);
  color: #fff;
}

@media (max-width: 768px) {
  /* Adjust styles for smaller screens here */
  .hero {
      height: 100vh; /* Reset height for better visibility */
  }
  .text-content,
  .image-content {
      flex: 1; /* Change flex values for single column layout */
      text-align: center; /* Center text content */
  }
  .hero button {
      width: 80%; /* Adjust button width */
  }
}
